import throttle from "lodash/throttle";
import PropTypes from "prop-types";
import React, { Component } from "react";
import SoothScroll from "smoothscroll-polyfill";

import AuthWrapper from "@/components/auth-wrapper";
import { TIMINGS } from "@/constants";
import withScreenDimensions from "@/utils/helpers/screen_dimensions";

import styles from "./index.module.scss";

class PageAnchorNavigation extends Component {
  constructor(props) {
    super(props);
    this.scrollToSection = this.scrollToSection.bind(this);
    this.handleScroll = this.handleScroll.bind(this);

    this.state = {
      isVisible: false,
    };

    this.throttledHandleScroll = throttle(
      this.handleScroll,
      TIMINGS.THROTTLE_SCROLL_TIME_OUT
    );
  }
  componentDidMount() {
    SoothScroll.polyfill();
    window.addEventListener("scroll", this.throttledHandleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.throttledHandleScroll);
  }
  handleScroll() {
    const { fixed, windowHeight } = this.props;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    if (!fixed) {
      return;
    }

    if (scrollTop > windowHeight * 0.7) {
      this.setState({ isVisible: true });
    } else {
      this.setState({ isVisible: false });
    }
  }
  scrollToSection(e, slug, moduleIndex) {
    if (!slug) {
      return;
    }

    e.preventDefault();
    const { fixed, windowHeight, forceFirstIndex } = this.props;

    if (
      (!fixed && moduleIndex === 0) ||
      (forceFirstIndex && moduleIndex === 0)
    ) {
      // If sub nav is not fixed and it's the first module then force scroll down 85% of screen height.
      window.scrollTo({
        behavior: "smooth",
        left: 0,
        top: windowHeight * 0.85,
      });

      return;
    }

    // Scroll window to appropriate section
    const destination = document.querySelector(`#${slug}`);

    if (destination) {
      window.scrollTo({
        behavior: "smooth",
        left: 0,
        top: destination.offsetTop - 170,
      });
    }
  }
  render() {
    const { isVisible } = this.state;
    const { moduleList, fixed, label } = this.props;

    return (
      <>
        {moduleList && moduleList.length > 0 && (
          <div
            className={`${
              fixed
                ? styles.pageAnchorNavigationFixed
                : styles.pageAnchorNavigationStatic
            } ${isVisible ? styles.pageAnchorNavigationVisible : ""}`}
          >
            <nav
              aria-label={label}
              className={`container ${styles.pageAnchorNavigation}`}
              role="navigation"
            >
              <ul>
                {moduleList.map((m, index) => {
                  const { label, slug, contentType } = m;

                  let li = (
                    <li key={label}>
                      <a
                        data-slug={slug}
                        href={`#${slug}`}
                        onClick={(e) => {
                          this.scrollToSection(e, slug, index);
                        }}
                        tabIndex={!isVisible && fixed ? -1 : 0}
                      >
                        <span>{label}</span>
                      </a>
                    </li>
                  );

                  if (contentType === "moduleLeadFlow") {
                    li = (
                      <React.Fragment key={slug}>
                        <AuthWrapper renderOnUserSession={false}>
                          <li>
                            <a
                              data-slug={slug}
                              href={`#${slug}`}
                              onClick={(e) => {
                                this.scrollToSection(e, slug, index);
                              }}
                              tabIndex={!isVisible && fixed ? -1 : 0}
                            >
                              <span>{label}</span>
                            </a>
                          </li>
                        </AuthWrapper>
                      </React.Fragment>
                    );
                  }

                  return li;
                })}
              </ul>
            </nav>
          </div>
        )}
      </>
    );
  }
}

PageAnchorNavigation.propTypes = {
  fixed: PropTypes.bool,
  forceFirstIndex: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  label: PropTypes.string,
  moduleList: PropTypes.array,
  windowHeight: PropTypes.number,
};

export default withScreenDimensions(PageAnchorNavigation);
