import Link from "next/link";
import PropTypes from "prop-types";
import React, { useContext } from "react";

import ClubAddress from "@/components/ClubAddress";
import AuthWrapper from "@/components/auth-wrapper";
import PrimaryCta from "@/components/buttons/primary-cta";
import PrimaryHeadline from "@/components/headlines/club-detail/primary";
import PageAnchorNavigation from "@/components/page-anchor-navigation";
import { LEAD_FLOW_CTA_SOURCE } from "@/components/seo-and-analytics/adobe-data-layer/acdl-data-layer/lead-flow";
import { ROUTES } from "@/constants";
import { DigitalDataLayerContext } from "@/context/DigitalDataLayer";

import FacilityHours from "../FacilityHours";
import styles from "./ClubPageHeroContent.module.scss";

const ClubPageHeroContent = ({
  club,
  clubData,
  clubStatus,
  clubType,
  data,
  displayHours,
  facility,
  flag,
  moduleList,
}) => {
  const { setLeadFlowInfo } = useContext(DigitalDataLayerContext);

  const handleOnClick = () => {
    if (
      !data.buttonUrl ||
      data.buttonUrl.startsWith(ROUTES.NEW_LEAD_FLOW_ROUTE)
    ) {
      setLeadFlowInfo({
        club,
        copy: data.comingSoonStayInTouchCopy,
        ctaEvent: `${data.buttonText} | ${LEAD_FLOW_CTA_SOURCE.HERO}`,
        data: data.scheduleAVisitModule.fields,
        isEmbedded: false,
      });
    }
  };

  const renderCTAs = (data) => {
    return (
      <AuthWrapper renderOnUserSession={false}>
        <Link
          href={
            data.buttonUrl ||
            `${ROUTES.NEW_LEAD_FLOW_ROUTE}/schedule-your-visit/${facility?.facilityId}`
          }
        >
          <a>
            <PrimaryCta label={data.buttonText} onClick={handleOnClick} />
          </a>
        </Link>
      </AuthWrapper>
    );
  };

  const showroomAddress = clubData?.showroomAddress?.fields;

  return (
    <>
      <div className={`row no-gutters`}>
        {flag && (
          <div className={`${styles.heroDetailsInnerFlag} col-lg-16`}>
            <span>{flag}</span>
          </div>
        )}
      </div>

      <div className={`${styles.heroDetailsRow} row no-gutters`}>
        <div
          className={`${styles.heroDetailsInnerClubName} col-lg-11 col-md-16 col-sm-16`}
        >
          <PrimaryHeadline clubType={clubType} text={facility.webName} />

          <p className={styles.heroDescription}>{data?.description}</p>

          <div
            className={`d-none d-lg-block ${styles.heroDetailsInnerClubNameCtas}`}
          >
            {renderCTAs(data)}
          </div>
        </div>

        <div
          className={`${styles.heroDetailsInnerClubDetails} col-lg-5 col-md-16 col-sm-16`}
        >
          <ClubAddress
            className={styles.heroAddress}
            clubType={clubType}
            contactInformation={facility.contactInformation}
            isPresale={facility.isPresale}
            label="Club Address"
          />

          {showroomAddress &&
            (clubStatus === "Presale" || clubStatus === "Coming Soon") && (
              <ClubAddress
                className={styles.heroAddress}
                clubType={clubType}
                contactInformation={showroomAddress}
                isPresale={facility.isPresale}
                label={"Showroom Address"}
              />
            )}

          {displayHours && <FacilityHours facility={facility} />}

          <div
            className={`d-none d-md-block d-sm-block d-xs-block d-lg-none ${styles.heroDetailsInnerClubNameCtas}`}
          >
            {renderCTAs(data)}
          </div>
        </div>
      </div>

      <div className={`${styles.heroDetailsInnerNav}`}>
        <PageAnchorNavigation
          fixed={false}
          forceFirstIndex={true}
          label="Club Detail Navigation"
          moduleList={moduleList}
        />
      </div>
    </>
  );
};

ClubPageHeroContent.propTypes = {
  club: PropTypes.object.isRequired,
  clubData: PropTypes.object.isRequired,
  clubStatus: PropTypes.string,
  clubType: PropTypes.string,
  data: PropTypes.object.isRequired,
  displayHours: PropTypes.bool,
  facility: PropTypes.object.isRequired,
  flag: PropTypes.string,
  moduleList: PropTypes.array,
};

export default ClubPageHeroContent;
