import PropTypes from "prop-types";
import React, { useState } from "react";

import DropDownArrow from "@/assets/icons/drop-down-arrow.svg";
import HolidayHours from "@/components/HolidayHours";
import { groupHolidayHours } from "@/utils/helpers/holidays";
import Helpers from "@/utils/helpers/response_handlers";

import styles from "./FacilityHours.module.scss";

const FacilityHours = ({ facility }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { serviceHours, holidays } = facility;
  const schedule = Helpers.convertFacilityApiLocationHours(
    serviceHours,
    "h:mma"
  );
  const groupedHolidays = groupHolidayHours(holidays || []);
  /*
    schedule is ordered from monday - sunday
    But Date.prototype.getDay() range is 0 - 6 which starts from sunday - saturday
    below logic is to map the schedule with right index
   */
  const dayIndex = new Date().getDay() === 0 ? 6 : new Date().getDay() - 1;
  const today = schedule?.[dayIndex];

  const renderTime = (day) => {
    if (day.isClosed) {
      return "Closed";
    }

    return (
      <>
        <time>{day.hours[0].open}</time>&nbsp;-&nbsp;
        <time>{day.hours[0].close}</time>
      </>
    );
  };

  return (
    <figure className={styles.facilityHours} data-is="FacilityHours">
      <figcaption className={styles.title}>
        {!schedule?.length > 0 || today?.isClosed
          ? "No hours today"
          : facility.isPresale
            ? "Showroom Hours"
            : "Hours today"}
      </figcaption>

      {today && (
        <div
          aria-expanded={isExpanded}
          className={styles.todaysHours}
          onClick={() => setIsExpanded(!isExpanded)}
          title="Toggle All Club Hours"
        >
          {renderTime(today)}

          <DropDownArrow aria-hidden="true" />
        </div>
      )}

      {schedule?.length > 0 && (
        <dl className={styles.hours} title="All Club Hours">
          {schedule?.map((day) => (
            <React.Fragment key={day.name}>
              <dt className={styles.day}>{day.name}</dt>
              <dd className={styles.times}>{renderTime(day)}</dd>
            </React.Fragment>
          ))}
        </dl>
      )}

      {groupedHolidays?.map((group) => (
        <HolidayHours data={group} key={group?.name} />
      ))}
    </figure>
  );
};

FacilityHours.propTypes = {
  facility: PropTypes.object,
};

export default FacilityHours;
