import PropTypes from "prop-types";
import React from "react";

import Utilities from "@/utils/helpers/formatting";

import styles from "./index.module.scss";

const PrimaryHeadline = ({ text, clubType }) => {
  return (
    <h2
      className={`${styles.primaryHeadline} ${styles[clubType]}`}
      data-is-long={text.split(" ").some((word) => word.length > 9)}
    >
      {Utilities.renderMultiLine(text)}
    </h2>
  );
};

PrimaryHeadline.propTypes = {
  clubType: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default PrimaryHeadline;
